import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { alpha, styled  } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: '1px solid #e2e2e1',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        '& .MuiInputBase-input': {
            paddingBottom: '0px',
            paddingTop: '22px',
            paddingLeft: '10px',
        },
        '& .MuiInputBase-inputSizeSmall': {
            paddingTop: '17px',
        },
        '&.Mui-disabled':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '&.Mui-disabled input':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
    },
    '& .MuiFormLabel-root.Mui-focused': {
        color: theme.palette.neutral.main,
    },
    '& .Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.secondary.main,
    },
    '& fieldset':{
        display: 'none',
        zIndex: "-1",
        backgroundColor: "#ffffff",
        border: '1px solid #e2e2e1',
        '& legend':{
            display: 'none',
        },
    },
    '& .MuiInputLabel-root': {
        top: '0',
        '&.Mui-focused': {
            top: '15px',
            boxShadow: 'none',
            borderColor: 'none',
        }
    },
    '& .MuiFormLabel-filled': {
        top: '15px',
    },
    '& .MuiInputBase-root': {
        padding: 4,
    },
}));

export default function DatePicker({onChange, placeholder, size, error, helperText, ...props}){
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                {...props}
                onChange={onChange}
                renderInput={(params) => <RedditTextField variant="outlined" size={size} placeholder={placeholder} helperText={helperText} fullWidth {...params} error={error} />}
            />
        </LocalizationProvider>
    );
}
