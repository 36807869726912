import { alpha, styled  } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

const RedditSelect = styled(({children, ...props}) => (
	<Select {...props}>
		{children}
	</Select>
	))(({ theme }) => ({
		'& .MuiSelect-select': {
			border: '1px solid #e2e2e1',
			overflow: 'hidden',
			borderRadius: 4,
			fontSize: '1.1rem',
			lineHeight: '1.25rem',
			paddingTop: "28px",
			paddingBottom: "0px",
			backgroundColor: '#ffffff !important',
			transition: theme.transitions.create([
				'border-color',
				'background-color',
				'box-shadow',
			]),
			'+ .MuiFormHelperText-root':{
				margin: '0 !important',
			},
			'&:hover': {
				backgroundColor: '#fff',
			},
			'& .Mui-focused': {
				backgroundColor: '#ffffff',
				boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
				borderColor: theme.palette.secondary.main,
			},
			'& .MuiFilledInput-root': {
				boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
				borderColor: theme.palette.secondary.main,
				'&:focus': {
					backgroundColor: '#fff',
				},
			},
		},
		'&.Mui-error:after': {
			border: 'none',
		},
		'&:before': {
			border: 'none',
		},
		'&:hover': {
			border: 'none',
			'&:before': {
				border: 'none !important',
			},
		},
}));

export const SelectLabel = styled(InputLabel)(({ theme }) => ({
	'&.Mui-focused':{
		top: '15px',
		color: theme.palette.neutral.main,
	},
	'&.MuiFormLabel-filled':{
		top: '15px',
	}
}));

export const HelperLabel = styled(FormHelperText)(({ theme }) => ({
	color: theme.palette.neutral.main,
}));

export default function SelectField({ children, label, id, error, helperText, ...props }){
	return (
		<FormControl fullWidth error={error}>
			<SelectLabel notched id={id}>{label}</SelectLabel>
			<RedditSelect {...props}>
				{children}
			</RedditSelect>
			{error && (
				<HelperLabel>{helperText}</HelperLabel>
			)}
		</FormControl>
	);
}
