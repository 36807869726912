import { Box, Dialog, DialogContent, DialogTitle, FormHelperText, IconButton, MenuItem, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import Autocomplete from '@components/Tags/Autocomplete';
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import useSWR from 'swr';
import debounce from 'lodash/debounce';
import { ucwords } from "@/components/Ucwords";
import LocalGasStationTwoToneIcon from '@mui/icons-material/LocalGasStationTwoTone';
import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Controller, useForm } from "react-hook-form";
import Image from "next/image";
import { motion, AnimatePresence } from "framer-motion";

import { CalendarPickerSkeleton, PickersDay } from '@mui/x-date-pickers';
import DatePicker from '@components/Tags/DatePicker';
import SelectField from '@/components/Tags/Select';
import dayjs from 'dayjs';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from "react-redux";
import {
    addInfo,
    removeInfo,
  } from '@redux/book.slice';
const minYear = 1992;
const fetcher = (url) => fetch(url).then((res) => res.json());

const fuelList = [
    {
        value: '1',
        label: 'GAS'
    },
    {
        value: '2',
        label: 'DIESEL'
    },
];
const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
};

let defaultValues = {
    province: "",
    city: "",
    branch: "",
    branch_name: "",
    service_date: "",
    schedule_time: "",
    schedule_id: "",
    brand: "",
    car_model: "",
    car_model_name: "",
    fuel_type: "",
};
//import branches from '../../../public/jsons/branches.json';
export default function BookingInfoForm({ className, submitForm = null, setSubmitForm, isAdmin = false }){
    const {
        handleSubmit,
        reset,
        resetField,
        setValue,
        setError,
        clearErrors,
        getValues,
        control,
        trigger,
        watch,
        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: defaultValues
     });

    const router = useRouter();
    const dispatch = useDispatch();
    const bkInfo = useSelector((state) => state.bkInfo);

    const { data: vehicles, error: vehicleError } = useSWR('/api/cache/get_vehicles', fetcher, swrOptions);
    const { data: branches, error: branchError } = useSWR('/api/cache/get_branches', fetcher, swrOptions);

    const [showBookingDialog, setShowBookingDialog] = useState(false);
    const [showGoogleMap, setShowGoogleMap] = useState(false);
    const [branchIframe, setBranchIframe] = useState(null);
    const [wazeLink, setWazeLink] = useState(null);

    const [vehicleInput, setVehicleInput] = useState('');
    const [pcInput, setPcInput] = useState('');

    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [randomPcOptions, setRandomPcOptions] = useState([]);
    const [pcOptions, setPcOptions] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);

    const [vehicleYearList, setVehicleYearList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const [pcList, setPcList] = useState([]);
    const [branchList, setBranchList] = useState([]);

    const [branchDetails, setBranchDetails] = useState(null);

    const [filteredVehicleList, setFilteredVehicleList] = useState([]);

    const [selectedVehicleYear, setSelectedVehicleYear] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedPc, setSelectedPc] = useState(null);

    const [loadingVehicles, setLoadingVehicles] = useState(null);
    const [loadingVehiclesText, setLoadingVehiclesText] = useState(null);
    const [vehiclesNoOptionText, setVehiclesNoOptionText] = useState('Please type to search your vehicle');

    const [loadingPc, setLoadingPc] = useState(null);
    const [loadingPcText, setLoadingPcText] = useState(null);
    const [pcNoOptionText, setPcNoOptionText] = useState('Search location');

    const [loadingBranch, setloadingBranch] = useState(null);
    const [loadingBranchText, setLoadingBranchText] = useState(null);
    const [branchNoOptionText, setBranchNoOptionText] = useState('Select your location first');

    const [fuelType, setFuelType] = useState(null);

    const onSubmit = (data) =>{
        let sd = dayjs(data.service_date).format('YYYY-MM-DD');
        data.service_date = sd;
        let vehicleArr = data.vehicle.split(",");
        //let branchArr = data.prov_city.split(",");
        data.city = branchDetails.CITY_NAME;
        data.province = branchDetails.PROV_NAME;
        data.branch_name = branchDetails.name;
        data.supply_charge = branchDetails.supply_charge_amount;
        data.brand = vehicleArr[1]; // model make
        //data.car_model = vehicleArr[0]; // model id
        data.car_model = vehicleArr[2]; // model name
        //data.car_model_name = vehicleArr[2]+' '+vehicleArr[3]; // model name and model year
        data.car_model_name = vehicleArr[2]; //model name
        dispatch(addInfo(data));
        if(!isAdmin){
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': "select_branch_model",
                province: data.province,
                city: data.city,
                branch_name: data.branch_name,
                vehicle_brand: data.brand,
                vehicle_model: data.car_model_name+' '+data.model_year,
                schedule_date: data.service_date,
                schedule_time: data.schedule_time
            });
            router.push('/book/service?step=1');
        }
    };

    const onError = (data) =>{
        if(data?.fuel_type) return;
        if(data?.vehicle) return;
        setShowBookingDialog(true);
    };

    const handleChangeVehicle = (data) =>{
        if(!data) return;
        setSelectedVehicle(data);
    };
    const handleChangeVehicleYear = (data) =>{
        if(!data) return;
        setSelectedVehicleYear(data);
    };

    const handleChangeFuelType = (fuel) => {
        setFuelType(fuel);
    };

    const handleChangePc = (data, init = null) => {
        setSelectedPc(data);
        setSelectedBranch(null);
        setShowGoogleMap(false);
        setBranchDetails(null);
        setBranchIframe(null);
        setWazeLink(null);
        let prov_city_val = data.value.split(",");
        let pcDetails = branches.find((branch) => {
            if(branch.prov_code == prov_city_val[1] && branch.city_code == prov_city_val[2]){
                return branch;
            }
        });
        let arrBranches = [];
        branches.find((branch) => {
            if(branch.prov_code == prov_city_val[1] && branch.city_code == prov_city_val[2]){
                arrBranches.push({
                    label: ucwords(branch.name),
                    value: branch.id,
                    description: branch.description,
                    is_default: branch.is_default
                });
            }
        });
        setBranchOptions(arrBranches);
        if(arrBranches.length == 1){
            handleChangeBranch(arrBranches[0]);
            setValue('branch', arrBranches[0].value);
        }
        else if(bkInfo?.branch && init){
            let selectedBranch = branches.find((branch) => {
                if(branch.id == bkInfo?.branch){
                    return branch;
                }
            });
            let sB = {label: ucwords(selectedBranch.name), value: selectedBranch.id, is_default: selectedBranch.is_default};
            handleChangeBranch(sB);
            setValue('branch', bkInfo?.branch);
        }
    };
    const handleChangeBranch = (data, init = null) => {
        if(!data) return;
        setSelectedBranch(data);
        setShowGoogleMap(false);
        setBranchDetails(null);
        setBranchIframe(null);
        setWazeLink(null);
        // resetField('service_date');
        // resetField('schedule_id');
        // setServiceDate(null);
        // setServiceTime(null);
        // clearErrors('schedule_id');
        let bDetails = branches.find((branch) => {
            if(branch.id == data.value){
                return branch;
            }
        });
        if(bDetails){
            setValue('payment_option_id', bDetails?.payment_option_id);
            setBranchDetails(bDetails);
            if(bDetails.map_embeded_link != "-" && bDetails.map_embeded_link){
                setBranchIframe(bDetails.map_embeded_link ? bDetails.map_embeded_link : null);
            }
            if(parseFloat(bDetails.lat) > 0){
                let ll = bDetails.lat+','+bDetails.long;
                let waze = "https://waze.com/ul?ll="+ll+"&navigate=yes&z=10";
                setWazeLink(waze);
            }
        }
        else{
            setBranchDetails(null);
            setBranchIframe(null);
            setWazeLink(null);
        }
        setShowBookingDialog(false);
    };

    const getVehicleOptionsAsync = (query, list) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    list.filter(
                        (o) => o.label.toLowerCase().indexOf(query.toLowerCase()) > -1,
                    ).slice(0, 10),
                );
            }, 1000);
        });
    };

    const getPcOptionsAsync = (query, list) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    list.filter(
                        (o) => o.label.toLowerCase().indexOf(query.toLowerCase()) > -1,
                    ).slice(0, 10),
                );
            }, 1000);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getVehicleOptionsDelayed = useCallback(
        debounce((text, list, callback) => {
            setVehicleOptions([]);
            getVehicleOptionsAsync(text, list).then(callback);
        }, 300),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPcOptionsDelayed = useCallback(
        debounce((text, list, callback) => {
            setPcOptions([]);
            getPcOptionsAsync(text, list).then(callback);
        }, 300),
        [],
    );

    useEffect(() => {
        if(vehicleList.length <= 0) return;
        setVehiclesNoOptionText(`No available vehicle.`);
        setLoadingVehiclesText('Please type to search your vehicle');
        setVehicleOptions(prev => []);
        if(vehicleInput.length <= 1) return;
        setLoadingVehiclesText(prev => 'Checking available vehicles...');
        getVehicleOptionsDelayed(vehicleInput, vehicleList, (filteredOptions) => {
            if(filteredOptions.length <= 0) setVehiclesNoOptionText(prev => `No available vehicle.`);
            setVehicleOptions(filteredOptions);
            setLoadingVehicles(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [vehicleInput, getVehicleOptionsDelayed]);

    // useEffect(() => {
    //     if(pcList.length <= 0) return;
    //     // setPcNoOptionText(`No available branch.`);
    //     // setLoadingPcText('Enter two or more letters');
    //     // setPcOptions(prev => []);
    //     // if(pcInput.length <= 1) return;
    //     setLoadingPcText(prev => 'Checking available branches...');
    //     getPcOptionsDelayed(pcInput, pcList, (filteredOptions) => {
    //         if(filteredOptions.length <= 0) setPcNoOptionText(prev => `No available branch.`);
    //         setPcOptions(filteredOptions);
    //         setLoadingPc(false);
    //     });
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [pcInput, getPcOptionsDelayed]);

    useEffect(()=>{
        if(!vehicles) return;
        let vList = [];
        vehicles.forEach(v => {
            // let value = [v.id, v.make, v.model, v.year];
            // vList.push({
            //     label: v.make + ' ' + v.model + ' ' + v.year,
            //     value: value.join()
            // });
            let value = [v.id, v.make, v.model];
            vList.push({
                label: v.make + ' ' + v.model,
                value: value.join()
            });
        });
        setVehicleList(vList);
        let maxYear = dayjs().format('YYYY');
        let yList = [];
        for (let i = minYear; i <= maxYear; i++) {
            yList.push({
                label: ""+i,
                value: i
            });
        }
        yList.push({
            label: 'Cannot Specify Year model',
            value: '-'
        });
        yList.reverse();
        setVehicleYearList(yList);
        if(bkInfo){
            if(!bkInfo?.vehicle) return;
            const vDetails = bkInfo?.vehicle?.split(",");
            let selectedVh = {
                label: vDetails[1]+' '+vDetails[2],
                value: bkInfo?.vehicle
            };
            let selectedVhYear = {
                label: bkInfo?.model_year == "-" ? 'Cannot Specify Year model' : bkInfo?.model_year+"",
                value: bkInfo?.model_year
            };
            handleChangeVehicleYear(selectedVhYear);
            handleChangeVehicle(selectedVh);
            setValue('model_year', bkInfo?.model_year);
        }
        //handleChangeVehicleYear(yList[yList.length - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vehicles]);

    useEffect(()=>{
        if(!branches) return;
        let pcList = [];
        branches.forEach(b => {
            let value = [b.id, b.prov_code, b.city_code];
            pcList.push({
                label: ucwords(b.PROV_NAME) + ', ' + ucwords(b.CITY_NAME),
                description: b.description,
                value: value.join()
            });
        });
        const removeDuplicateArrPc = pcList.filter((value, index) => {
            const temp_value = { label: value.label };
            const _value = JSON.stringify(temp_value);
            return index === pcList.findIndex(obj => {
                const temp_obj = { label: obj.label };
                return JSON.stringify(temp_obj) === _value;
            });
        });
        setPcOptions(removeDuplicateArrPc);
        //setPcList(removeDuplicateArrPc);
        // const shuffled = removeDuplicateArrPc.sort(() => 0.5 - Math.random());
        // let top5 = shuffled.slice(0, 5);
        // setRandomPcOptions(top5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[branches]);

    useEffect(()=>{
        if(!bkInfo) return;
        if(!branches) return;
        try {
            handleChangeFuelType(bkInfo?.fuel_type);
            setValue('fuel_type', bkInfo?.fuel_type);
            setValue('vehicle', bkInfo?.vehicle);
            let selectedPc = {
                label: ucwords(bkInfo?.province)+', '+ucwords(bkInfo?.city),
                value: bkInfo?.prov_city
            };
            handleChangePc(selectedPc, 1);
            setValue('prov_city', bkInfo?.prov_city);
        } catch (error) {
            //console.log(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bkInfo, branches]);

    useEffect(()=>{
        if(!submitForm) return;
        trigger();
        async function submitData() {
            if(isValid){
                handleSubmit(onSubmit)();
            }
            else{
                if(errors?.fuel_type) return;
                if(errors?.vehicle) return;
                setShowBookingDialog(true);
                setSubmitForm(null);
            }
        }
        submitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[submitForm]);
    return(
        <div className={className}>
            <form className="flex flex-col justify-between gap-2" onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="flex flex-col gap-2">
                    <div
                        className="relative bg-white rounded-md border border-gray-200 w-full cursor-pointer px-3 py-2.5 group"
                        onClick={()=>setShowBookingDialog(true)}
                    >
                        {/*bkInfo?.branch_name && (
                            <p className="text-sm font-bold"><span className="text-gray-400 sm:pr-2 pr-1">Branch:</span>{bkInfo?.branch_name}</p>
                        )}
                        {bkInfo?.service_date && (
                            <p className="text-sm font-bold"><span className="inline-block text-gray-400 sm:pr-2 pr-1 w-[61.95px]">Date:</span>{dayjs(bkInfo?.service_date).format('MMMM D, YYYY')}  <span className="text-gray-400 sm:px-2 px-1">Time:</span>{bkInfo?.schedule_time}</p>
                        )*/}
                        {(!branchDetails?.name && !bkInfo?.branch_name) ? (
                            <p className="text-lg text-gray-500 py-1 group-hover:text-rapide-red" suppressHydrationWarning>Choose Rapide Branch</p>
                        ) : (
                            <Fragment>
                                {!branchDetails ? (
                                    <p className="text-lg font-bold text-gray-500 group-hover:text-rapide-red" suppressHydrationWarning>Select a branch</p>
                                ):(
                                    <p className="text-lg font-bold group-hover:text-rapide-red" suppressHydrationWarning>{branchDetails?.name ? branchDetails?.name : bkInfo?.branch_name}</p>
                                )}
                            </Fragment>
                        )}
                        <ArrowDropDownIcon className="text-gray-500 absolute top-1.5 right-1 group-hover:text-rapide-red group-hover:bg-gray-200 rounded-full" fontSize="large"/>
                    </div>
                    <div className="grid sm:grid-cols-10 grid-cols-5 sm:gap-1 gap-2 justify-between align-top items-start">
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Autocomplete
                                        className="w-full col-span-5"
                                        fontBold={true}
                                        id="vehicle"
                                        name="vehicle"
                                        label="Select Car model"
                                        disablePortal={false}
                                        error={errors.vehicle != null}
                                        helperText={errors.vehicle && errors.vehicle?.message}
                                        //filterOptions={(x) => x}
                                        loading={loadingVehicles}
                                        loadingText={loadingVehiclesText}
                                        //noOptionsText={vehiclesNoOptionText}
                                        // onInputChange={(e, vehicleInput) => {
                                        //     setLoadingVehicles(true);
                                        //     setVehicleInput(vehicleInput);
                                        // }}
                                        onChange={(e, attr)=>{
                                            if(attr){
                                                onChange(attr.value);
                                                handleChangeVehicle(attr);
                                            }
                                        }}
                                        value={selectedVehicle}
                                        //list={vehicleOptions}
                                        list={vehicleList}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.value}>
                                                    {option.label}
                                                </li>
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value}
                                    />
                                )
                            }}
                            name="vehicle"
                            control={control}
                            rules={{ required: "Please select a car model" }}
                        />
                        <div className="col-span-5 flex flex-row sm:space-x-1 space-x-2 justify-between">
                            <Controller
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { isTouched, isDirty, error },
                                }) => {
                                    return(
                                        <Autocomplete
                                            className="w-full basis-3/5"
                                            fontBold={true}
                                            id="model_year"
                                            name="model_year"
                                            label="Select Year model"
                                            disablePortal={false}
                                            error={errors.model_year != null}
                                            helperText={errors.model_year && errors.model_year?.message}
                                            loading={loadingVehicles}
                                            loadingText={loadingVehiclesText}
                                            onChange={(e, attr)=>{
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeVehicleYear(attr);
                                                }
                                            }}
                                            value={selectedVehicleYear}
                                            list={vehicleYearList}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.value}>
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            isOptionEqualToValue={(option, value) => option.id === value}
                                        />
                                    )
                                }}
                                name="model_year"
                                control={control}
                                rules={{ required: "Please select a car model year" }}
                            />
                            <Controller
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { isTouched, isDirty, error },
                                }) => {
                                    return(
                                        <div className="basis-2/5 flex flex-col">
                                            <ToggleButtonGroup
                                                className={`sm:space-x-1 space-x-2`}
                                                id="fuel_type"
                                                name="fuel_type"
                                                label="Fuel"
                                                variant="filled"
                                                size="small"
                                                color="neutral"
                                                value={fuelType}
                                                exclusive
                                                fullWidth
                                                onChange={(e, attr) => {
                                                    if(attr !== null){
                                                        onChange(attr);
                                                        handleChangeFuelType(attr);
                                                    }
                                                }}
                                                aria-label="Platform"
                                                >
                                                    {fuelList.map((type, i) => {
                                                        let icon = type.value == 1 ? <LocalGasStationOutlinedIcon fontSize="small"/> : <LocalGasStationRoundedIcon fontSize="small"/>;
                                                        return(
                                                            <ToggleButton
                                                                key={i+type.value}
                                                                value={type.value}
                                                                className={`!bg-white !p-0 !pt-1 !pb-0.5 relative flex flex-col !border-l !border-gray-200 !rounded`}
                                                            >
                                                                {icon}
                                                                {type.label}
                                                                <span className={`${ errors.fuel_type != null ? 'animate-ping  border-b-2 border-rapide-red rounded-full' : ''} absolute inset-y-2/4 w-1/6`}>&nbsp;</span>
                                                            </ToggleButton>
                                                        )
                                                    })}
                                            </ToggleButtonGroup>
                                            {errors.fuel_type != null && (
                                                <FormHelperText className="text-rapide-red ml-1">{errors.fuel_type && errors.fuel_type?.message}</FormHelperText>
                                            )}
                                        </div>
                                    )
                                }}
                                name="fuel_type"
                                control={control}
                                rules={{ required: "Please select a fuel type" }}
                            />
                        </div>
                    </div>
                    <Dialog
                        className={``}
                        fullWidth
                        maxWidth={'sm'}
                        open={showBookingDialog}
                        onClose={()=>setShowBookingDialog(false)}
                        sx={
                            {
                                '& .MuiPaper-root': {
                                    width: '100%',
                                    margin: `0px`,
                                },
                            }
                        }
                        disablePortal
                        keepMounted
                    >
                        <DialogTitle className="relative flex flex-col bg-black px-4 py-2">
                            <label className="font-bold tracking-tight text-white">Choose Rapide Branch</label>
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={()=>setShowBookingDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 0,
                                color: "#fff",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent className='relative flex flex-col md:px-4 px-2'>
                            <div className="flex flex-col gap-2" id="booking-info">
                                <div className="flex flex-col">
                                    <Controller
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: { isTouched, isDirty, error },
                                        }) => {
                                            return(
                                                <Autocomplete
                                                    className="w-full"
                                                    id="prov_city"
                                                    name="prov_city"
                                                    label="Please enter your city"
                                                    disablePortal={false}
                                                    error={errors.prov_city != null}
                                                    helperText={errors.prov_city && errors.prov_city?.message}
                                                    loading={loadingPc}
                                                    loadingText={loadingPcText}
                                                    noOptionsText={pcNoOptionText}
                                                    onChange={(e, attr)=>{
                                                        if(attr){
                                                            onChange(attr.value);
                                                            handleChangePc(attr);
                                                        }
                                                    }}
                                                    value={selectedPc}
                                                    list={pcOptions}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.value}>
                                                                {option.label}
                                                            </li>
                                                        );
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.id === value}
                                                />
                                            )
                                        }}
                                        name="prov_city"
                                        control={control}
                                        rules={{ required: "Please select your location" }}
                                    />
                                    <div className="flex flex-row gap-1 mt-2">
                                        <Controller
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { isTouched, isDirty, error },
                                            }) => {
                                                return(
                                                    <Autocomplete
                                                        className="w-full"
                                                        id="branch"
                                                        name="branch"
                                                        label="Choose available branch"
                                                        disablePortal={false}
                                                        error={errors.branch != null}
                                                        helperText={errors.branch && errors.branch?.message}
                                                        loading={loadingBranch}
                                                        loadingText={loadingBranchText}
                                                        noOptionsText={branchNoOptionText}
                                                        onChange={(e, attr)=>{
                                                            if(attr){
                                                                onChange(attr.value);
                                                                handleChangeBranch(attr);
                                                            }
                                                        }}
                                                        openOnFocus
                                                        value={selectedBranch}
                                                        list={branchOptions}
                                                        renderOption={(props, option) => {
                                                            let className = "MuiAutocomplete-option border-b border-gray-200 last:border-none";
                                                            let addedProps = {...props, className};
                                                            return (
                                                                <li {...addedProps} key={option.value}>
                                                                    <Box className="border border-gray-200 last:border-none">
                                                                        <p>{option.label}</p>
                                                                        <p className="text-sm text-gray-600 ml-1">{option.description}</p>
                                                                    </Box>
                                                                </li>
                                                            );
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.id === value}
                                                    />
                                                )
                                            }}
                                            name="branch"
                                            control={control}
                                            rules={{ required: "Please select a branch" }}
                                        />
                                        {branchIframe && (
                                            <button
                                                type="button"
                                                className={`relative w-12 transition-all ${showGoogleMap ? 'grayscale' : 'grayscale-0'}`}
                                                onClick={()=>setShowGoogleMap(!showGoogleMap)}
                                            >
                                                <Image
                                                    src={"/assets/images/google-maps.webp"}
                                                    alt="google-map-icon"
                                                    fill
                                                />
                                            </button>
                                        )}
                                    </div>
                                    {branchDetails && (
                                        <div className=''>
                                            <div className='hidden flex-col gap-1 text-center text-sm text-gray-600'>
                                                <p>{branchDetails.email}</p>
                                                {branchDetails.contact_no && (
                                                        <p>{branchDetails.contact_no}</p>
                                                    )}
                                                    {branchDetails.description && (
                                                        <p>{branchDetails.description}</p>
                                                    )}
                                            </div>
                                            <AnimatePresence>
                                                {(showGoogleMap && branchIframe) && (
                                                    <motion.div
                                                        className='relative w-full mb-2'
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                    >
                                                        <div
                                                        className='w-full'
                                                        dangerouslySetInnerHTML={{
                                                            __html: branchIframe,
                                                        }}></div>
                                                        {wazeLink && (
                                                            <div className='absolute top-1 right-1'>
                                                                <a target="_blank" href={wazeLink} rel="noopener noreferrer">
                                                                    <div className='block overflow-hidden w-[50px]'>
                                                                        <Image
                                                                            src={"/assets/images/waze.svg"}
                                                                            alt="waze"
                                                                            layout='fixed'
                                                                            width={50}
                                                                            height={50}
                                                                        />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                {!isAdmin && (
                    <button
                        type="submit"
                        className="flex flex-row align-middle justify-center items-center w-full px-2 py-1 font-bold bg-rapide-yellow border border-gray-950 text-black rounded-md"
                        onClick={()=>{
                            // trigger();
                            // if(isValid){
                            //     handleSubmit(onSubmit, onError)();
                            // }
                        }}
                    >
                        {watch('model_year') && (
                            <KeyboardDoubleArrowRightIcon className="animate-bounce-right" fontSize="small"/>
                        )}
                        Get Free Estimate
                        {watch('model_year') && (
                            <KeyboardDoubleArrowLeftIcon className="animate-bounce-left" fontSize="small"/>
                        )}
                    </button>
                )}
            </form>
        </div>
    )
}