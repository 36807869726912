import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { alpha, styled  } from '@mui/material/styles';

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: '1px solid #e2e2e1',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        '& .MuiInputBase-input': {
            paddingBottom: '2.5px',
            paddingTop: '15px',
            paddingLeft: '10px',
        },
        '&.Mui-disabled':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '&.Mui-disabled input':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        }
    },
    '& .Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.secondary.main,
    },
    '& fieldset':{
        zIndex: "-1",
        top: "-1px",
        backgroundColor: "#ffffff",
        border: '1px solid #e2e2e1',
        '& legend':{
            display: 'none',
        },
    },
    '& .MuiFormLabel-root': {
        top: '-3px',
    },
    '& label[id="available_days-label"].MuiFormLabel-root': {
        top: '10px',
    },
    '& label[id="blocked_dates-label"].MuiFormLabel-root': {
        top: '10px',
    },
    '& .MuiOutlinedInput-root .MuiChip-root': {
        marginTop: '0px',
        marginBottom: '0px',
    },
    '& .MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot ': {
        paddingTop: '10px !important',
        paddingBottom: '0px !important',
    },
    '& .MuiFormLabel-root.Mui-focused': {
        top: '12px',
        color: theme.palette.neutral.main,
        boxShadow: 'none',
        border: 'none',
    },
    '& .MuiFormLabel-filled':{
        top: '12px',
    },
    '& .MuiInputBase-root': {
        padding: 4,
    },
    '& input[id="blocked_dates"].MuiInputBase-inputSizeSmall': {
        height: '31px',
    },
    '& input[id="available_days"].MuiInputBase-inputSizeSmall': {
        height: '31px',
    },
}));

const RedditTextFieldBold = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: '1px solid #e2e2e1',
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        '& .MuiInputBase-input': {
            paddingBottom: '2.5px',
            paddingTop: '15px',
            paddingLeft: '10px',
            fontWeight: 'bold',
        },
        '&.Mui-disabled':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        },
        '&.Mui-disabled input':{
            color: '#000000',
            WebkitTextFillColor: '#000000',
            backgroundColor: '#ffffff',
        }
    },
    '& .Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.secondary.main,
    },
    '& fieldset':{
        zIndex: "-1",
        top: "-1px",
        backgroundColor: "#ffffff",
        border: '1px solid #e2e2e1',
        '& legend':{
            display: 'none',
        },
    },
    '& .MuiFormLabel-root': {
        top: '-3px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
        top: '12px',
        color: theme.palette.neutral.main,
        boxShadow: 'none',
        border: 'none',
    },
    '& .MuiFormLabel-filled':{
        top: '12px',
    },
    '& .MuiInputBase-root': {
        padding: 4,
    },
}));

export default function ComboBox({noOptionsText = 'No option', size = 'medium', fontBold = false, renderTags = null, disablePortal = true, label, id, name, onChange, freeSolo = false, disableCloseOnSelect = false, multiple = false, disabled = false, renderOption = null, filterOptions, loading, loadingText, onInputChange, value, defaultValue, getOptionSelected, error, helperText, list, className}) {
  return (
    <Autocomplete
      size={size}
      multiple={multiple}
      disabled={disabled}
      freeSolo={freeSolo}
      disablePortal={disablePortal}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      id={id}
      name={name}
      options={list}
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={onChange}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      onInputChange={onInputChange}
      className={className}
      getOptionLabel={(option) => option?.label || ""}
      renderTags={renderTags}
      renderOption={renderOption}
      renderInput={(params) => {
            if(!fontBold){
                return(
                    <RedditTextField error={error} variant="outlined" helperText={helperText} {...params} label={label} />
                );
            }
            else{
                return(
                    <RedditTextFieldBold error={error} variant="outlined" helperText={helperText} {...params} label={label} />
                );
            }
      }}
    />
  );
}